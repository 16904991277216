$bgPath: "assets/img/minigame/bg.png";
$bgSuccessPath: "assets/img/minigame/bg_success.png";
$topPath: "assets/img/minigame/top.svg";
$centerPath: "assets/img/minigame/center.svg";
$wheelBgPath: "assets/img/minigame/wheel-bg.svg";

.public_checkin {
  margin: 0 auto;
  background-color: #3a9ef9;
  display: table;
  width: 100%;
  height: 100vh;
  position: relative;

  &__background {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  .info_checkin {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translate(-50%, -50%);
    display: grid;
    color: black;
    background-color: white;
    padding: 5px;
    border-radius: 4px;
  }

  .info_checkin .row_info {
    display: flex;
    font-size: 20px;
    font-weight: 900;
  }

  .info_checkin .row_info .title_info {
    font-size: 20px;
    font-weight: 900;
  }

  .info_checkin .row_info .number {
    margin: 0 5px;
  }

  .form_input {
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    display: block;

    .first-line {
      display: inline-flex;

      .qrcode_scanner_btn {
        display: none;
        font-size: 23px;
      }
    }
  }
  // .title_input {
  //   font-size: 40px;
  //   font-weight: 900;
  //   color: #000;
  // }
  // .white-color {
  //   color: #fff;
  // }
  #input_id {
    font-size: 40px;
    color: #000;
    border: none;
    border: 1px solid #000;
  }
  #type_check {
    font-size: 25px;
    color: #6c99ba;
    font-weight: 900;
    height: 40px;
    margin-right: 15px;
  }

  .qrcode_scanner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: none;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);

    &__container {
      width: 280px;
      height: 280px;
      padding: 5px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border: 1px solid #135da7;
      border-radius: 14px;
    }
  }

  .checkin_error {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: none;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);

    &__container {
      width: 350px;
      height: 120px;
      padding: 5px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border: 1px solid #135da7;
      border-radius: 14px;

      h4 {
        margin-bottom: 15px;
      }
    }
  }

  #checkbox_alert {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: none;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .checkbox_alert__container {
    width: 70vw;
    padding: 30px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid #135da7;
    border-radius: 30px;
  }
  .error {
    font-weight: 700;
    color: #c0392b;
  }

  .info_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .info_row .item,
  .info_row .item-full {
    display: flex;
    flex-direction: column;
    width: 48%;
    justify-content: flex-start;
  }
  .info_row .item-full {
    width: 100%;
  }

  .input_disabled {
    background: #f5aeb1;
    width: 100%;
    height: 32px;
    font-size: 18px;
    width: 100%;
    border-radius: 8px;
    padding: 5px;
    font-weight: 700;
    color: #135da7;
    margin-top: 5px;
    border: none;
    pointer-events: none;
  }

  .form {
    width: 80%;
    background-color: #ffffff;
  }
  .form .title {
    width: 100%;
    font-size: 18px;
    color: #135da7;
    font-family: "iCiel panton black";
    margin-top: 10px;
  }

  .title_form {
    font-weight: 700;
    color: #135da7;
    font-family: "iCiel panton black";
    font-size: 20px;
    margin-bottom: 20px;
  }

  .btn_confirm {
    border: none;
    border-radius: 14px;
    font-size: 20px;
    color: #fff;
    // background: linear-gradient(to bottom, #135da7, #135da7, #c79370);
    background: #135da7;
    padding: 10px 20px;
    font-weight: 900;
    cursor: pointer;
    height: 50px;
  }

  button:disabled,
  button[disabled] {
    color: #747474;
  }

  .avatar_img_path {
    width: 300px;
    height: 300px;
    cursor: pointer;
    border: 1px solid #ccc;
    margin: 0 auto;
    position: relative;
  }

  .icon-capture {
    position: absolute;
    bottom: 10px;
    right: 44%;
  }

  .webcam img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  .webcam_container {
    cursor: pointer;
    width: 300px;
    height: 300px;
    margin: 0 auto;
    position: relative;
  }

  .webcam_container .border {
    border: 1px solid #ccc;
  }

  // .webcam_container .capture {
  //   background
  // }
}

/** Responsive **/
@media (max-width: 768px) {
  .public_checkin {
    .qrcode_scanner_btn {
      display: block !important;
    }

    #input_id {
      font-size: 32px;
    }
    .checkbox_alert__container {
      width: 100vw;
      padding: 15px;
    }

    .form {
      width: 100%;
    }
  }
}

@media (max-width: 420px) {
  .public_checkin {
    #input_id {
      font-size: 25px;
    }

    .checkbox_alert__container {
      width: 100vw;
      padding: 15px;
    }

    .form {
      width: 100%;
    }
  }
}